import { mapActions } from 'pinia'
import useWebStore from '../stores/web'
import type { App } from 'vue'
import type { FormInput } from '@/modules/Core/types/FormBuilder'
import deepcopy from 'ts-deepcopy'

const methods = {
    ...mapActions(useWebStore, ['get', 'put', 'post', 'postFile', 'delete']),
    prepareDataToSend(data: any, inputs: FormInput[], originalData: any) {
        if (!data || !inputs || !originalData) return {}
        const originalDataClone = deepcopy(originalData)
        const dataClone = deepcopy(data)
        let updatedItem: any = {}
        let getitems = function (
            inputs: FormInput[],
            itemActual: any,
            originalItem: any
        ) {
            inputs.forEach((input) => {
                if (input.ignore) return
                let inputs = 'inputs' in input ? input.inputs : null,
                    subItem = 'subItem' in input ? input.subItem : null
                if ('type' in input)
                    if (input.type == 'code') {
                        inputs = [
                            {
                                type: 'select',
                                name: 'type_code',
                            },
                            {
                                type: 'text',
                                name: 'code',
                            },
                            {
                                type: 'text',
                                name: 'secondary_code',
                            },
                        ] as FormInput[]
                    } else if (input.type == 'address') {
                        inputs = [
                            {
                                type: 'select',
                                name: 'country',
                            },
                            {
                                type: 'text',
                                name: 'postalcode',
                            },
                            {
                                type: 'text',
                                name: 'state',
                            },
                            {
                                type: 'text',
                                name: 'city',
                            },
                            {
                                type: 'text',
                                name: 'district',
                            },
                            {
                                type: 'text',
                                name: 'street',
                            },
                            {
                                type: 'number',
                                name: 'number',
                            },
                            {
                                type: 'text',
                                name: 'complement',
                            },
                        ] as FormInput[]
                    }

                if (inputs) {
                    if (subItem) {
                        getitems(
                            inputs,
                            itemActual[subItem] ?? {},
                            originalItem[subItem] ?? {}
                        )
                    } else {
                        getitems(inputs, itemActual, originalItem)
                    }
                } else if ('name' in input) {
                    if (Array.isArray(itemActual[input.name])) {
                        const originalVal = [
                            ...(originalItem[input.name] ?? []),
                        ] as any[]
                        const sortedItemActual = itemActual[input.name].sort()
                        if (
                            originalVal.length !=
                                itemActual[input.name].length ||
                            (!!input.input?.select &&
                                originalVal
                                    .map((i) => i[input.input.select])
                                    .sort()
                                    .some(
                                        (i, idx) => i != sortedItemActual[idx]
                                    ))
                        ) {
                            const sendName = input.onSendName
                                ? input.onSendName
                                : input.name
                            updatedItem[sendName] = input.filter
                                ? input.filter(sortedItemActual)
                                : sortedItemActual
                        }
                    } else {
                        if (input.type == 'model-select' && input.select) {
                            if (originalItem[input.name])
                                originalItem[input.name] =
                                    originalItem[input.name][input.select]
                            if (itemActual[input.name])
                                itemActual[input.name] =
                                    itemActual[input.name][input.select]
                        }

                        if (
                            originalItem[input.name] !=
                                itemActual[input.name] &&
                            !(
                                input.preventSendOnEmpty &&
                                String(itemActual[input.name]).length == 0
                            )
                        ) {
                            const sendName = input.onSendName
                                ? input.onSendName
                                : input.name
                            updatedItem[sendName] = input.filter
                                ? input.filter(itemActual[input.name])
                                : itemActual[input.name]
                        }
                    }
                }
            })
        }
        getitems(inputs, dataClone, originalDataClone)
        return updatedItem
    },
    globalUpdateObject(object: object, path: string, value: any) {
        const search = function (path: string, obj: any, value: any) {
            let arr = path.split(/\.(.+)/s)
            // Remove empty strings
            while (arr[arr.length - 1].length == 0) arr.pop()
            let last = arr.pop()
            arr.forEach((element: string) => {
                if (!obj[element] || typeof obj[element] != 'object')
                    obj[element] = {}
                obj = obj[element]
            })
            if (last) obj[last] = value
            return obj
        }
        search(path, object, value)
        return object
    },
}

export default function (app: App) {
    app.mixin({ methods })
}

export const globalUpdateObject = methods.globalUpdateObject
export const prepareDataToSend = methods.prepareDataToSend
