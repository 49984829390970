<template>
    <router-view />
    <cookie-law></cookie-law>
</template>

<script lang="ts">
import CookieLaw from '@/modules/Core/components/app/CookieLaw.vue'
import { mapActions, mapState } from 'pinia'
import useLayoutStore from '../stores/layout'
import { useQuasar } from 'quasar'
import { definePalette } from '../helper/theme'
import useUserStore from '../stores/user'

export default {
    name: 'App',
    computed: {
        ...mapState(useLayoutStore, ['locale', 'theme']),
        ...mapState(useUserStore, ['isLoggedIn']),
    },
    methods: {
        ...mapActions(useLayoutStore, ['changeLocale']),
        ...mapActions(useUserStore, [
            'updateRoutes',
            'updateModules',
            'updatePermissions',
        ]),
    },
    mounted() {
        //@ts-ignore
        this.$i18n.locale = this.locale
        //@ts-ignore
        this.changeLocale(this.locale, useQuasar())
        definePalette(this.theme.light)

        if (this.isLoggedIn) {
            // On refresh page, reload necessary data
            this.updateRoutes()
            this.updateModules()
            this.updatePermissions()
        }
    },
    components: { CookieLaw },
}
</script>
