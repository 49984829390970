import { createPinia, setActivePinia } from 'pinia'
import piniaPluginPersistedstate, {
    createPersistedState,
} from 'pinia-plugin-persistedstate'
import encryptStorage from '../libs/encryptstorage'

//Criptografa a informação que é salva no frontend

const pinia = createPinia()
const installPersistedStatePlugin = createPersistedState({
    storage: import.meta.env.PROD
        ? {
              getItem: (key: string) => {
                  return JSON.stringify(encryptStorage.getItem(key, true))
              },
              setItem: (key: string, value: string) =>
                  encryptStorage.setItem(key, value, true),
          }
        : {
              getItem: (key: string) => {
                  return String(localStorage.getItem(key))
              },
              setItem: (key: string, value: string) =>
                  localStorage.setItem(key, value),
          },
})
//const installPersistedStatePlugin = piniaPluginPersistedstate
pinia.use(installPersistedStatePlugin)

export default pinia
