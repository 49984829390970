import { defineStore, acceptHMRUpdate } from 'pinia'
import useWebStore from './web'
import pinia from '../plugins/pinia'
import axios from '@libs/axios'
import defaultTheme, { definePalette, type Theme } from '../helper/theme'
import useUserStore from './user'
import type { QVueGlobals } from 'quasar'
import quasarEnUs from 'quasar/lang/en-US'
import quasarPtBr from 'quasar/lang/pt-BR'
import quasarEs from 'quasar/lang/es'
import type { Resource } from '../types/resource'
import deepcopy from 'ts-deepcopy'
const quasarLangs = {
    en_US: quasarEnUs,
    pt_BR: quasarPtBr,
    es: quasarEs,
} as any

const useLayoutStore = defineStore('layout', {
    state: () => ({
        drawer: true,
        settingsDrawer: true,
        dark: false,
        mini: false,
        hasLogo: false,
        theme: deepcopy(defaultTheme),
        clientType: 'client',
        locale: (navigator.language || process.env.APP_LOCALE || 'en').replace(
            '-',
            '_'
        ),
        qtdDefaultRows: 20,

        business: {
            name: '',
        },
    }),
    actions: {
        changeLocale(locale: string, quasar: QVueGlobals) {
            this.locale = locale
            axios.defaults.headers.common['locale'] = locale
            axios.defaults.headers.common['Accept-Language'] = locale
            document.querySelector('html')?.setAttribute('lang', locale)
            if (locale in quasarLangs) quasar.lang.set(quasarLangs[locale])
            else quasar.lang.set(quasarLangs['en_US'])
        },
        updateTheme() {
            const webStore = useWebStore(pinia)
            const userStore = useUserStore(pinia)

            return webStore
                .get<Resource<any>>({
                    url:
                        'core/config/layout' +
                        (userStore.isAdmin ? '/admin' : ''),
                    locale: '[App@UpdateTheme]',
                    silent: true,
                })
                .then((response) => {
                    let obj = {
                        ...defaultTheme.light,
                        ...response.data.data.theme.light,
                    }
                    let light = definePalette(obj)
                    this.hasLogo = response.data.data.exists_logo || false
                    this.clientType = response.data.data.client_type

                    this.business.name = response.data.data.name

                    this.theme = {
                        light,
                        dark: this.theme.dark,
                    }
                })
                .catch((responseError) => {})
        },
    },
    persist: true,
})

export default useLayoutStore
