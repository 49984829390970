import { defineStore, acceptHMRUpdate } from 'pinia'
import Toast from '@libs/toast'
import i18n from '@plugins/i18n'
import axios from '@libs/axios'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'

export type RequestParams = {
    url: string
    locale: string
    data?: any
    silent?: boolean
    config?: AxiosRequestConfig
}

function catchHandler<T>(error: { response?: AxiosResponse<T> }) {
    if (error.response) {
        return error.response
    }

    return { status: 499 } as AxiosResponse<T>
}

const useWebStore = defineStore('web', {
    actions: {
        async checkErrors<T>(
            response: AxiosResponse<T>,
            silent: boolean = false
        ) {
            if (!response) throw response

            const emitToast = silent ? () => {} : Toast

            let error = true
            switch (response.status) {
                case 401:
                    // Should ignore this erros in this function, and not throw any warning
                    break
                case 422: // Request enviado apresenta inputs invalidos
                    emitToast({
                        icon: 'warning',
                        title: i18n.global
                            .t('Core.alerts.invalid_data')
                            .toString(),
                    })
                    break
                case 429: // Muitos requests em um curto periodo de tempo
                    emitToast({
                        icon: 'error',
                        title: i18n.global
                            .t('Core.alerts.many_requests')
                            .toString(),
                    })
                    break
                case 408: // Time out
                    emitToast({
                        icon: 'error',
                        title: i18n.global.t('Core.alerts.timeout').toString(),
                    })
                    break
                case 403: // Autenticado mas sem privilégios para tal
                    emitToast({
                        icon: 'info',
                        title: i18n.global
                            .t('Core.alerts.unathorized')
                            .toString(),
                    })
                    break
                case 404: // Servidor retornou que não encontrou a informação
                    emitToast({
                        icon: 'warning',
                        title: i18n.global
                            .t('Core.alerts.not_found')
                            .toString(),
                    })
                    break
                case 503:
                    emitToast({
                        icon: 'warning',
                        title: i18n.global
                            .t('Core.alerts.maintenance')
                            .toString(),
                    })
                    break
                default:
                    if (response.status <= 599 && response.status >= 500) {
                        //Erro interno no servidor
                        emitToast({
                            icon: 'error',
                            title: i18n.global
                                .t('Core.alerts.internal_error')
                                .toString(),
                        })
                    } else if (
                        response.status <= 499 &&
                        response.status >= 400
                    ) {
                        emitToast({
                            icon: 'error',
                            title: i18n.global
                                .t('Core.alerts.error_happened')
                                .toString(),
                        })
                    } else if (
                        response.status <= 299 &&
                        response.status >= 200
                    ) {
                        error = false
                    }
                    break
            }

            if (error) throw response

            return response
        },
        async get<T = any>({ url, locale, silent, config }: RequestParams) {
            const response = await axios
                .get<T>(url, config)
                .catch(catchHandler<T>)
            return this.checkErrors(response, silent)
        },
        async post<T = any>({
            url,
            locale,
            silent,
            data,
            config,
        }: RequestParams) {
            const response = await axios
                .post<T>(url, data, config)
                .catch(catchHandler<T>)
            return this.checkErrors(response, silent)
        },
        async postFile<T = any>({
            url,
            locale,
            silent,
            data,
            config,
        }: RequestParams) {
            let form = new FormData()
            Object.keys(data).forEach((key) => {
                if (data[key] instanceof File) {
                    form.append(key, data[key], data[key].name)
                } else form.append(key, data[key])
            })
            const response = await axios
                .post<T>(url, form, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    ...config,
                })
                .catch(catchHandler<T>)
            return this.checkErrors(response, silent)
        },
        async put<T = any>({
            url,
            locale,
            silent,
            data,
            config,
        }: RequestParams) {
            const response = await axios
                .put<T>(url, data, config)
                .catch(catchHandler<T>)
            return this.checkErrors(response, silent)
        },
        async delete<T = any>({ url, locale, silent, config }: RequestParams) {
            const response = await axios
                .delete<T>(url, config)
                .catch(catchHandler<T>)
            return this.checkErrors(response, silent)
        },
        async del<T = any>({ url, locale, silent, config }: RequestParams) {
            const response = await axios
                .delete<T>(url, config)
                .catch(catchHandler<T>)
            return this.checkErrors(response, silent)
        },
    },
})

export default useWebStore
