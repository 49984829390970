import type { App } from 'vue'

export default function (app: App) {
    app.config.globalProperties.$filters = {
        routeName(route: string) {
            if (!route) return 'title.page.login_user'
            let aux = route.split('@')
            if (aux.length > 0) return aux[0] + '.routes.' + aux[1]
            return 'Core.routes.' + aux[0]
        },
    }
}
