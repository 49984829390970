import { createApp } from 'vue'

/** Import css in javascript
 * as encouraged in laravel and vite docs
 */
//import '/resources/sass/app.scss'

/**Setup all plugins */
import setupPlugins from '@plugins/setup'

/**Setup all libraries */
import setupLibs from '@libs/setup'

import AppView from '@src/vue/App.vue'

const app = createApp(AppView)

setupPlugins(app)
setupLibs(app)

app.mount('#app')
