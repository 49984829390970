// Import CSS for flag icons
//import 'flag-icons/css/flag-icons.min.css'

import './axios'
import './toast'

import type { App } from 'vue'

/**Load filters*/
import setupFilters from '@src/helper/filters'
/**Load vue components for each module */
import setupComponents from '@libs/components'
/**Load Mixin */
import setupMixin from '@src/helper/mixin'

export default function (app: App) {
    if (import.meta.env.PROD) {
        app.config.performance = true
    }

    setupFilters(app)
    setupComponents(app)
    setupMixin(app)
}
