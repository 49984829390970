import { defineStore } from 'pinia'
import type { BreadCrumb } from '../types/BreadCrumb'
import type { Lang } from '../types/lang'

export type AppState = {
    langs: Lang[]
    mini: boolean
    lazyLoadingRoute: boolean
    routerStack: BreadCrumb[]
    version: string

    hotReloadKey: number
}

const useAppStore = defineStore('app', {
    state: () =>
        ({
            langs: window.LANGS_PHP,
            mini: false,
            lazyLoadingRoute: false,
            routerStack: [],
            version: window.FULL_VERSION_PHP,

            hotReloadKey: 0,
        }) as AppState,
})

// Force vue router to hot reload on async views and components
if (import.meta.hot) {
    import.meta.hot.on('vite:afterUpdate', (data) => {
        if (data) {
            const regx = new RegExp('^\\/client\\/modules\\/[a-zA-Z0-9]+\\/.*')
            if (
                data.updates.some((element) => {
                    return regx.test(element.path)
                })
            ) {
                useAppStore().hotReloadKey++
            }
        }
    })
}

export default useAppStore
