import type { importType } from '../modules'
//@ts-ignore
import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js'

const availableLangs = window.LANGS_PHP.map((lang) =>
    lang.abbr.replaceAll('-', '_')
)

const module_langs: { [lang: string]: any } = {}
for (const lang of availableLangs) {
    module_langs[lang] = {}
}

const langsImport: importType = import.meta.glob(
    '@client/modules/*/langs/**/*.json',
    {
        eager: true,
    }
)

const langs: { [idx: string]: any } = {}

for (const moduleName in langsImport) {
    const arr = moduleName.split('/')
    if (!langs[arr[3]]) langs[arr[3]] = {}
    langs[arr[3]][arr[5].split('.')[0]] = langsImport[moduleName].default
}

for (const moduleName in langs) {
    let module: { [lang: string]: any } = {}
    for (const lang of availableLangs) {
        module[lang] = langs[moduleName][lang]
        if (module[lang]) {
            if (moduleName == 'Core') {
                module[lang]['app_name']['v2'] = module[lang]['app_name'][
                    'v2'
                ].replaceAll('APP_NAME', process.env.APP_NAME)
                module[lang]['app_name']['v1'] = module[lang]['app_name'][
                    'v1'
                ].replaceAll('APP_NAME', process.env.APP_NAME)
            }
            module_langs[lang][moduleName] = module[lang]
        }
    }
}

/**
 * Aqui a situação é um pouco complicada
 * 1-Para cada linguagem deve ser feita manualmente a adição do time format da região
 * 2-Só existem short e long?
 * 3-A linguagem n pode usar _ e deve usar o -
 * 4-depende da biblioteca formatjs e de melhorias na mesma
 * 5-se estiver na base da normalidade, todos as linguagem terão os mesmos dados para printar, e a biblioteca vai deixar na formatação correta (10/28/2001, 05.12.2001,etc...)
 */
const dateTimeAll = {
    date: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    },
    short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    },
    month: {
        year: 'numeric',
        month: 'long',
    },
    datetime: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    },
    long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    },
}
const datetimeFormats = {
    en: dateTimeAll,
    'pt-BR': dateTimeAll,
    es: dateTimeAll,
}

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'USD',
        },
    },
    'pt-BR': {
        currency: {
            style: 'currency',
            currency: 'BRL',
        },
    },
    es: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
    },
}

export default createI18n({
    legacy: false,
    fallbackLocale: 'pt_BR',
    datetimeFormats,
    numberFormats,
    messages: module_langs,
    globalInjection: true,
})
