import { colors, setCssVar } from 'quasar'
const { lighten } = colors

export type Theme = {
    primary: string
    secondary: string
    accent: string
    positive: string
    negative: string
    info: string
    warning: string
    primary_lighten1?: string
    primary_lighten2?: string
    primary_lighten3?: string
    primary_lighten4?: string
    primary_lighten5?: string
    secondary_lighten1?: string
    secondary_lighten2?: string
    secondary_lighten3?: string
    secondary_lighten4?: string
    secondary_lighten5?: string
    primary_darken1?: string
    primary_darken2?: string
    primary_darken3?: string
    primary_darken4?: string
    primary_darken5?: string
    secondary_darken1?: string
    secondary_darken2?: string
    secondary_darken3?: string
    secondary_darken4?: string
    secondary_darken5?: string
}

const defaultTheme: { light: Theme; dark: Theme } = {
    light: {
        primary: '#4392F1',
        secondary: '#e0e0e0',
        accent: '#253C78',
        negative: '#f44336',
        warning: '#ff9800',
        info: '#00bcd4',
        positive: '#8bc34a',
    } as Theme,
    dark: {
        primary: '#A0A',
        secondary: '#A0A',
        accent: '#A0A',
        negative: '#A0A',
        info: '#A0A',
        positive: '#A0A',
        warning: '#A0A',
    } as Theme,
}

export function definePalette(palette: Theme, dark = false) {
    if (!palette) return palette

    if (palette.primary) {
        setCssVar('primary', palette.primary)
        setCssVar('primary-lighten1', lighten(palette.primary, 10))
        setCssVar('primary-lighten2', lighten(palette.primary, 20))
        setCssVar('primary-lighten3', lighten(palette.primary, 35))
        setCssVar('primary-lighten4', lighten(palette.primary, 50))
        setCssVar('primary-lighten5', lighten(palette.primary, 70))
        setCssVar('primary-darken1', lighten(palette.primary, -10))
        setCssVar('primary-darken2', lighten(palette.primary, -25))
        setCssVar('primary-darken3', lighten(palette.primary, -40))
        setCssVar('primary-darken4', lighten(palette.primary, -60))
        setCssVar('primary-darken5', lighten(palette.primary, -80))
    }
    if (palette.secondary) {
        setCssVar('secondary', palette.secondary)
        setCssVar('secondary-lighten1', lighten(palette.secondary, 10))
        setCssVar('secondary-lighten2', lighten(palette.secondary, 20))
        setCssVar('secondary-lighten3', lighten(palette.secondary, 35))
        setCssVar('secondary-lighten4', lighten(palette.secondary, 50))
        setCssVar('secondary-lighten5', lighten(palette.secondary, 70))
        setCssVar('secondary-darken1', lighten(palette.secondary, -10))
        setCssVar('secondary-darken2', lighten(palette.secondary, -25))
        setCssVar('secondary-darken3', lighten(palette.secondary, -40))
        setCssVar('secondary-darken4', lighten(palette.secondary, -60))
        setCssVar('secondary-darken5', lighten(palette.secondary, -80))
    }
    if (palette.info) setCssVar('info', palette.info)
    if (palette.accent) setCssVar('accent', palette.accent)
    if (palette.negative) {
        setCssVar('negative', palette.negative)
        setCssVar('negative-lighten1', lighten(palette.negative, 10))
        setCssVar('negative-lighten2', lighten(palette.negative, 20))
        setCssVar('negative-lighten3', lighten(palette.negative, 35))
        setCssVar('negative-lighten4', lighten(palette.negative, 50))
        setCssVar('negative-lighten5', lighten(palette.negative, 70))
        setCssVar('negative-darken1', lighten(palette.negative, -10))
        setCssVar('negative-darken2', lighten(palette.negative, -25))
        setCssVar('negative-darken3', lighten(palette.negative, -40))
        setCssVar('negative-darken4', lighten(palette.negative, -60))
        setCssVar('negative-darken5', lighten(palette.negative, -80))
    }
    if (palette.positive) {
        setCssVar('positive', palette.positive)
        setCssVar('positive-lighten1', lighten(palette.positive, 10))
        setCssVar('positive-lighten2', lighten(palette.positive, 20))
        setCssVar('positive-lighten3', lighten(palette.positive, 35))
        setCssVar('positive-lighten4', lighten(palette.positive, 50))
        setCssVar('positive-lighten5', lighten(palette.positive, 70))
        setCssVar('positive-darken1', lighten(palette.positive, -10))
        setCssVar('positive-darken2', lighten(palette.positive, -25))
        setCssVar('positive-darken3', lighten(palette.positive, -40))
        setCssVar('positive-darken4', lighten(palette.positive, -60))
        setCssVar('positive-darken5', lighten(palette.positive, -80))
    }
    if (palette.warning) setCssVar('warning', palette.warning)
    return palette
}
//defaultTheme.dark = definePalette(defaultTheme.dark, true)
//defaultTheme.light = definePalette(defaultTheme.light, false)

export default defaultTheme
